import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Layout from "../../wrapper";
import { logInUserInfo } from "../../Actions/Users";
import axios from "axios";
import moment from "moment";
import { ReportsList } from "../../Components/Reports/index";
import withRoutes from "../../HOC/HocRoutes";

let source;
let serverTimeZoneName = "Asia/Dubai";
// let serverTimeZone = "Asia/Dubai";

const formDefault = {
  from: "",
  to: "",
  reportNumber: "", //compulsory
  vehicleIds: [],
  contractNumbers: [],
  detail: false,
  mail: false,
  predictionStatus: "",
  maxFrequency: "",
  minFrequency: "",
};
class Reports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      loaderR: false,
      userInfo: {},
      userFetch: false,
      form: { ...formDefault },
      contractType: "",
      searchInvoiceText: "",
      accounts: "",
      mailAccounts: [],
      page: "",
      pageSize: -1,
      makingAccounts: {},
      makingUsers: {},
      makingVehicles: {},
      filename: "",
      valid: false,
      validTo: false,
      validFrom: false,
      accounts1: [],
      accounts2: [],
      combinedAccounts: [],
      reportKey: "",
      garageVerticals: [],
      area: "",
      additionalEmail: {},
      disableEmail: true,
    };

    this.selecteFilters = this.selecteFilters.bind(this);
  }

  checkDates = () => {
    if (this.state.validTo && this.state.validFrom) {
      this.setState({
        valid: true,
      });
    }
  };
  selecteFilters = async (
    from,
    to,
    report,
    account,
    vehicle,
    type,
    allVehiclesSelected,
    formData
  ) => {
    let reportNumber = report.key || report;

    if (type === "emailExcel" || type === "emailPdf") {
      this.setState(
        {
          form: {
            to: to,
            from: from,
            contractNumbers: account,
            reportNumber: report?.key || report,
            vehicleIds: vehicle,
            detail: true,
            mail: true,
            area: formData?.area || "",
            garageVerticals: formData?.garageVerticals || [],
          },
        },
        () => {
          this.downloadInvoices(
            moment(from).format("YYYY-MM-DD"),
            to,
            reportNumber,
            this.state.form.contractNumbers,
            this.state.form.vehicleIds,
            this.state.form.detail,
            this.state.form.mail,
            type,
            allVehiclesSelected,
            formData?.area,
            formData?.garageVerticals
          );
        }
      );
    } else {
      this.setState(
        {
          form: {
            to: to,
            from: from,
            contractNumbers: account,
            reportNumber: report?.key || report,
            vehicleIds: vehicle,
            detail: false,
            mail: false,
            area: formData?.area || "",
            garageVerticals: formData?.garageVerticals || [],
            minFrequency: formData?.minFrequency || 0,
            maxFrequency: formData?.maxFrequency || 0,
            predictionStatus: formData?.predictionStatus || "",
          },
        },
        () => {
          this.downloadInvoices(
            from,
            to,
            reportNumber,
            this.state.form.contractNumbers,
            this.state.form.vehicleIds,
            this.state.form.detail,
            this.state.form.mail,
            type,
            allVehiclesSelected,
            formData?.area,
            formData?.garageVerticals,
            formData?.minFrequency,
            formData?.maxFrequency,
            formData?.predictionStatus
          );
        }
      );
    }
  };

  downloadInvoices = (
    from,
    to,
    reportNumber,
    contractNumbers,
    vehicles,
    detail,
    mail,
    type,
    allVehiclesSelected,
    area,
    garageVerticals,
    minFrequency,
    maxFrequency,
    predictionStatus
  ) => {
    let additionalEmail = this.state.additionalEmail.email;
    // let  additionalEmail  = "usama@test.com";
    let vehicleIds = allVehiclesSelected ? [] : vehicles;
    let obj;
    let apiUrl = "/api/billings";
    if (
      this.state.form.reportNumber === 1 ||
      this.state.form.reportNumber.key === 1 ||
      this.state.form.reportNumber === 2 ||
      this.state.form.reportNumber.key === 2 ||
      this.state.form.reportNumber === 3 ||
      this.state.form.reportNumber.key === 3 ||
      this.state.form.reportNumber === 101 ||
      this.state.form.reportNumber.key === 101 ||
      this.state.form.reportNumber === 111 ||
      this.state.form.reportNumber.key === 111 ||
      this.state.form.reportNumber === 112 ||
      this.state.form.reportNumber.key === 112
    ) {
      obj = JSON.stringify({
        from,
        to,
        reportNumber,
        contractNumbers,
        vehicleIds,
        detail,
        mail,
      });
    } else if (
      this.state.form.reportNumber === 121 ||
      this.state.form.reportNumber.key === 121 ||
      this.state.form.reportNumber === 125 ||
      this.state.form.reportNumber.key === 125 ||
      this.state.form.reportNumber === 126 ||
      this.state.form.reportNumber.key === 126
    ) {
      obj = JSON.stringify({
        from,
        to,
        reportNumber,
        contractNumbers,
        vehicleIds,
        detail,
        mail,
        area,
        garageVerticals,
      });
    } else if (
      this.state.form.reportNumber === 118 ||
      this.state.form.reportNumber.key === 118
    ) {
      obj = JSON.stringify({
        from,
        to,
        reportNumber,
        contractNumbers,
        vehicleIds,
        detail,
        mail,
        minFrequency,
        maxFrequency,
        predictionStatus,
        additionalEmail,
      });
    } else if (
      this.state.form.reportNumber === 113 ||
      this.state.form.reportNumber.key === 113
    ) {
      obj = JSON.stringify({
        from,
        to,
        reportNumber,
        contractNumbers,
        vehicleIds,
        detail,
        mail,
        minFrequency,
        maxFrequency,
        //  predictionStatus,
        additionalEmail,
      });
    } else {
      obj = JSON.stringify({
        from,
        to,
        reportNumber,
        contractNumbers,
        detail,
        mail,
        additionalEmail,
        // vehicleIds,
      });
    }

    // if (this.state.form.reportNumber === 118 || this.state.form.reportNumber.key === 118) {
    //   apiUrl = `/api/billings?predictionStatus=${this.state.predictionStatus}`;
    // }
    if (type === "emailExcel" || type === "emailPdf") {
      let header;
      let accept;

      if (type === "emailExcel") {
        accept =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      } else if (type === "emailPdf") {
        accept = "application/pdf";
      }

      header = {
        headers: new Headers({
          "Content-Type": "application/json",
          Accept: accept,
        }),
        method: "POST",
        body: obj,
      };

      this.setState(
        {
          loader: true,
        },
        () => {
          fetch(apiUrl, { ...header })
            .then((response) => {
              this.setState({ loader: false });
              if (response.ok) {
                // this.setState({ detail: this.state.detail1 });

                toast.success(this.props.translate("emailSentSuccessfully"));
              } else {
                throw response;
              }
            })
            .catch((error) => {
              // errorHandler(error,this.props.dispatch)
              if (error && error.text) {
                error.text().then((err) => {
                  let message = err.split("-")[0];
                  if (message) {
                    toast.error(this.props.translate(message));
                  }
                });
              }
              this.setState({ loader: false });
            });
        }
      );
    } else {
      let accept;
      let fileName = this.state.filename;
      if (type === "downloadPdf") {
        accept = "application/pdf";
      } else {
        accept =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      }
      this.setState(
        {
          loader: true,
        },
        () => {
          fetch(apiUrl, {
            method: "POST",
            headers: {
              Accept: accept,
              // "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              "Content-Type": "application/json",
            },
            body: obj,
          })
            .then((response1) => {
              if (response1.status === 200) {
                if (type === "downloadPdf") {
                  response1.blob().then((response) => {
                    this.setState(
                      {
                        loader: false,
                      },
                      () => {
                        this.saveData(response, `${fileName}.pdf`);
                      }
                    );
                  });
                } else {
                  response1.blob().then((response) => {
                    this.setState(
                      {
                        loader: false,
                      },
                      () => {
                        this.saveData(response, `${fileName}.xlsx`);
                      }
                    );
                  });
                }
              } else {
                throw response1;
              }
            })
            .catch((error) => {
              // errorHandler(error, this.props.dispatch)
            });
        }
      );
    }
  };

  saveData = (blob, filename) => {
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      const a = document.createElement("a");
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = filename;

      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
        // this.setState({ loading: false })
      }, 0);
    }
  };

  handleChange = (name, value) => {
    let timezone = "";
    if (
      this.props.logInUser &&
      this.props.logInUser.attributes &&
      this.props.logInUser.attributes.timezone
    ) {
      timezone = this.props.logInUser.attributes.timezone;
    }
    moment.tz.setDefault(timezone);

    if (name === "contractType") {
      this.setState({
        contractType: value.target.value,
      });
    } else if (name === "reportNumber") {
      this.setState(
        {
          form: {
            ...this.state.form,
            [name]: value,
            to: "",
            from: "",
          },
          filename: value.name,
          // accounts:this.state.combinedAccounts,
          reportKey: value.key,
        },
        () => {
          // if(value.key === 2   || value.key === 7   || value.key === 8   ||
          //    value.key === 105 || value.key === 106 || value.key === 107 ||
          //    value.key === 108 || value.key === 109 || value.key === 110 ||
          //    value.key === 111 || value.key === 112 || value.key === 113 ||
          //    value.key === 10  || value.key ===  9  || value.key === 115 ||
          //    value.key === 11  || value.key === 117 || value.key === 13  ||
          //    value.key === 16 || value.key === 118 || value.key === 122
          //    ) {
          this.getContractsData();
          // }
        }
      );
    } else if (name === "from") {
      // let val = moment(value).startOf("month").startOf("day").utc().format();
      let val;
      // console.log("this====", this.state.form.reportNumber);
      if (
        this.state.form.reportNumber === 102 ||
        this.state.form.reportNumber.key === 102 ||
        this.state.form.reportNumber === 5 ||
        this.state.form.reportNumber.key === 5 ||
        this.state.form.reportNumber?.key === 12 ||
        this.state.form.reportNumber === 12 ||
        this.state.form.reportNumber?.key === 6 ||
        this.state.form.reportNumber === 6 ||
        this.state.form.reportNumber?.key === 11 ||
        this.state.form.reportNumber === 11 ||
        this.state.form.reportNumber?.key === 19 ||
        this.state.form.reportNumber === 19 ||
        this.state.form.reportNumber?.key === 114 ||
        this.state.form.reportNumber === 114 ||
        this.state.form.reportNumber?.key === 103 ||
        this.state.form.reportNumber === 103 ||
        this.state.form.reportNumber?.key === 115 ||
        this.state.form.reportNumber === 115 ||
        this.state.form.reportNumber?.key === 130 ||
        this.state.form.reportNumber === 130 ||
        // this.props.form?.reportNumber?.key === 131 ||
        // this.props.form?.reportNumber === 131 ||
        this.state.form.reportNumber?.key === 129 ||
        this.state.form.reportNumber === 129
      ) {
        // val = moment(value).startOf("month").startOf("day").utc().format("2020-12-31T21:00:00.000Z");
        val = "2020-12-31T21:00:00.000Z";
      } else {
        //  val = moment(value).startOf("month").startOf("day").utc().format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
        val =
          moment(value).startOf("day").utc().format("YYYY-MM-DDTHH:mm:ss.SSS") +
          "Z";
      }
      this.setState(
        {
          form: {
            ...this.state.form,
            [name]: val.toString(),
          },
          validFrom: true,
        },
        () => this.checkDates()
      );
    } else if (name === "to") {
      let val;

      if (
        this.state.form.reportNumber === 102 ||
        this.state.form.reportNumber.key === 102 ||
        this.state.form.reportNumber === 5 ||
        this.state.form.reportNumber.key === 5 ||
        this.state.form.reportNumber?.key === 12 ||
        this.state.form.reportNumber === 12 ||
        this.state.form.reportNumber?.key === 6 ||
        this.state.form.reportNumber === 6 ||
        this.state.form.reportNumber?.key === 11 ||
        this.state.form.reportNumber === 11 ||
        this.state.form.reportNumber?.key === 19 ||
        this.state.form.reportNumber === 19 ||
        this.state.form.reportNumber?.key === 114 ||
        this.state.form.reportNumber === 114 ||
        this.state.form.reportNumber?.key === 103 ||
        this.state.form.reportNumber === 103 ||
        this.state.form.reportNumber?.key === 115 ||
        this.state.form.reportNumber === 115 ||
        this.state.form.reportNumber?.key === 130 ||
        this.state.form.reportNumber === 130 ||
        // this.props.form?.reportNumber?.key === 131 ||
        // this.props.form?.reportNumber === 131 ||
        this.state.form.reportNumber?.key === 129 ||
        this.state.form.reportNumber === 129
      ) {
        val =
          moment(value).endOf("day").utc().format("YYYY-MM-DDTHH:mm:59.59") +
          "Z";
        let fromVal = "2020-12-31T21:00:00.000Z";
        this.setState(
          {
            form: {
              ...this.state.form,
              [name]: val.toString(),
              from: fromVal.toString(),
            },
            validTo: true,
          },
          () => this.checkDates()
        );
      } else {
        //  val = moment(value).startOf("month").startOf("day").utc().format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
        val =
          moment(value).endOf("day").utc().format("YYYY-MM-DDTHH:mm:59.59") +
          "Z";

        // val = moment(value).endOf("month").endOf("day").utc().format("YYYY-MM-DDTHH:mm:59.59") + "Z";
        this.setState(
          {
            form: {
              ...this.state.form,
              [name]: val.toString(),
            },
            validTo: true,
          },
          () => this.checkDates()
        );
      }
    } else if (name === "area") {
      this.setState({
        form: {
          ...this.state.form,
          [name]: value.target.value,
        },
      });
    }
    // else if (name === "additionalEmail") {
    //   this.setState({
    //       [name]: value,
    //   },()=>this.checkReqFields());
    // }
    else if (name === "garageVerticals") {
      this.setState((prevState) => ({
        form: {
          ...prevState.form,
          [name]: value,
        },
      }));
    }
    // else if (name === "predictionStatus") {
    //   this.setState({
    //     [name]: value.target.value,
    //   });
    // }
    else if (
      name === "minFrequency" ||
      name === "maxFrequency" ||
      name === "predictionStatus"
    ) {
      let val =
        name === "minFrequency" || name === "maxFrequency"
          ? parseInt(value.target.value)
          : value.target.value;
      this.setState({
        form: {
          ...this.state.form,
          [name]: val,
        },
      });
    } else {
      this.setState(
        {
          form: {
            ...this.state.form,
            [name]: value,
          },
          additionalEmail: value,
        },
        () => this.checkReqFields()
      );
    }
  };

  checkReqFields = () => {
    const { additionalEmail } = this.state;
    const emailValid = additionalEmail?.email !== null;

    // Set disableEmail based on conditions
    if (emailValid) {
      this.setState({ disableEmail: false });
    } else {
      this.setState({ disableEmail: true });
    }
  };

  getContractsData = () => {
    let apiUrl;
    let list = {};
    // console.log("here we goo=====", );
    let usersId = {};
    if (this.state.contractType === 1) {
      if (this.state.reportKey === 101) {
        apiUrl = `/api/accounts/list?all=true&contractType=1&contractType=3&userTypes=3&userId=${this.props.logInUser.id}&limit=-1&search=${this.state.searchInvoiceText}`;
      } else {
        apiUrl = `/api/accounts/list?all=true&contractType=1&userTypes=3&userId=${this.props.logInUser.id}&limit=-1&search=${this.state.searchInvoiceText}`;
      }
    } else if (this.state.contractType === 2) {
      apiUrl = `/api/accounts/list?all=true&userId=${this.props.logInUser.id}&contractType=2&userTypes=3&limit=-1&search=${this.state.searchInvoiceText}`;
    }

    source = axios.CancelToken.source();
    this.setState(
      {
        accounts: [],
        mailAccounts: [],
        loaderR: false,
      },
      () => {
        axios
          .get(apiUrl, {
            cancelToken: source.token,
          })
          .then((response) => {
            let dataRes = response?.data?.data?.data || [];
            dataRes &&
              dataRes.map(
                (d) => (
                  (list[d.contractNumber] = true), (usersId[d.userId] = true)
                )
              );

            let filteredAccounts = dataRes.filter(
              (account) => account.email !== null
            );

            this.setState({
              accounts: response?.data?.data || {},
              mailAccounts: filteredAccounts || [],
              makingAccounts: list,
              makingUsers: usersId,
              loader: false,
              loaderR: true,
            });
          })
          .catch((e) => {
            console.log("e ===", e);
          });
      }
    );
  };

  searchItem = (text) => {
    source.cancel();

    this.setState(
      {
        searchInvoiceText: text.target.value,
      },
      () => {
        setTimeout(() => {
          this.getContractsData();
        }, 500);
        // this.getContractsData();
      }
    );
  };

  render() {
    // console.log("ser=====", this.props.ServerSetting);
    // console.log("login=====", this.props.logInUser);
    if (
      this.props.ServerSetting &&
      this.props.ServerSetting.attributes &&
      this.props.ServerSetting.attributes.timezone
    ) {
      serverTimeZoneName = this.props.ServerSetting.attributes.timezone;
    }
    if (
      this.props.logInUser &&
      this.props.logInUser.attributes &&
      this.props.logInUser.attributes.timezone
    ) {
      serverTimeZoneName = this.props.logInUser.attributes.timezone;
    }
    if (this.props.logInUser) {
      return (
        <Layout
          getContractsData={this.getContractsData}
          searchItem={this.searchItem}
          user={this.props.logInUser}
          {...this.state}
          {...this.props}
          form={this.state.form}
          handleChange={this.handleChange}
          selecteFilters={this.selecteFilters}
          handleSubmit={this.handleSubmit}
          downloadInvoices={this.downloadInvoices}
          // childFunction={this.myFunction}
        >
          <ReportsList {...this.props} />
        </Layout>
      );
    } else {
      fetch(
        "/api/session?app=3b8d4deb84c0d9b65070c872e2f73626f23d8dc8789a7a05",
        {
          headers: { Accept: "application/json; odata=verbose" },
        }
      )
        .then((response) => {
          if (response.ok) {
            response.json().then((res) => {
              if (res.status === "success") {
                let userInfo = res.data;
                this.props.setActiveLanguage(
                  userInfo?.attributes?.lang || "en"
                );
                this.props.dispatch(logInUserInfo(userInfo));
              } else if (res?.statusCode === "440") {
                window.location.replace("/login");
              } else if (res.statusCode) {
                var err = res?.message.split(":");
                err[1] = err[1].replace(")", "");
                toast.error(this.props.translate(err[1]));
                this.props.navigate("/login");
              }
            });
          } else {
            this.props.navigate("/login");
            throw response;
          }
        })
        .catch((e) => {
          toast.error("somethingWentWrong");
        });
      return null;
    }
  }
}

const mapStateToProps = (state) => ({
  ServerSetting: state.ServerSetting,
  logInUser: state.logInUsers,
});

export default connect(mapStateToProps)(withRoutes(Reports));
