import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { getRoles } from './../../Actions/Roles'
import MaterialIcon from 'material-icons-react'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSatelliteDish } from '@fortawesome/free-solid-svg-icons'
import { checkPrivileges } from '../../Helpers'
import { ReactComponent as RoleManageIcon } from './../../assets/nav/role-manage.svg'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

library.add(faSatelliteDish)

class Role extends Component {
  constructor (props) {
    super(props)
    this.state = {
      callPending: true
    }
  }

  fatchRoles = () => {
    if (checkPrivileges('role')) {
      this.setState(
        {
          callPending: false
        },
        () => {
          if (!(this.props.roles && this.props.roles.length)) {
            if (this.props.logInUser.userType === -1) {
              fetch(`/api/roles?userId=${this.props.logInUser.id}&all=true`).then(response => {
                if (response.ok) {
                  response.json().then(res => {
                    if(res.status ==='success'){  
                      let role = res.data
                      this.props.dispatch(getRoles(role))
                    }
                    else if(res?.statusCode === '440'){
                      window.location.replace('/login')
                    }
                    else if(res.statusCode){
                      var err = res?.message.split(':')
                      err[1] =err[1].replace(')', "")
              
                        toast.error(
                      this.props.translate(err[1])
                      )
                    }
                  })
                }
              })
              .catch(e => {
                toast.error('somethingWentWrong')
            })
            } else {
              fetch(`/api/roles?userId=${this.props.logInUser.id}`).then(
                response => {
                  if (response.ok) {
                    response.json().then(res => {
                      if(res.status ==='success'){ 
                        let role = res.data
                        this.props.dispatch(getRoles(role))
                      }
                      else if(res?.statusCode === '440'){
                        window.location.replace('/login')
                      }
                      else if(res.statusCode){
                        var err = res?.message.split(':')
                        err[1] =err[1].replace(')', "")
                
                          toast.error(
                             this.props.translate(err[1])
                        )
                      }
                    })
                  }
               
              }) 
              .catch(e => {
              toast.error(
                'somethingWentWrong')
            })
            }
          }
        }
      )
    }
  }
  render () {
    if (this.props.logInUser && this.state.callPending) {
      this.fatchRoles()
    }

    let data = ''
    if (this.props.roles && this.props.roles.length) {
      data = this.props.roles.map(role => (
        <ListRow
          key={role.id}
          data={role}
          {...this.props}
          selecteItem={this.props.selecteItem}
          selectedItemId={this.props.selectedItemId}
          removedItem={this.props.removedItem}
          editItem={this.props.editItem}
        />
      ))
    }

    return (
      <div>
        {data && (
          <div className='individual-view'>
            <ul className='list-view'>{data}</ul>
          </div>
        )}
      </div>
    )
  }
}

export class ListRows extends Component {
  render () {
    if (this.props.children.length) {
      return (
        <ul className='list-view'>
          {this.props.children.map(data => (
            <ListRow key={data.id} data={data} {...this.props} />
          ))}
        </ul>
      )
    } else {
      return ''
    }
  }
}
export class ListRow extends Component {
  render () {
    // if (this.props.logInUser.userType === -1 || this.props.logInUser.userType === 5 ) {
      return (
        <li>
          <Link
            className={
              'clearfix list-row ' +
              (parseInt(this.props?.match?.params?.id) === this.props.data.id
                ? 'active'
                : '')
            }
            to={`/${this.props.pathName}/` + this.props.data.id}
          >
            <label
              className='checkbox'
              onClick={() => this.props.selecteItem(this.props.data)}
            >
              <span
                className='unit-name'
                style={{
                  minWidth: 220,
                  maxWidth: 220,
                  marginLeft: 0,
                  lineHeight: '24px',
                  verticalAlign: 'middle'
                }}
              >
                {/* <span
                  style={{
                    marginRight: '5px',
                    display: 'inline-block',
                    verticalAlign: 'middle'
                  }}
                >  */}
                 {/* <svg
                  fill='currentColor'
                  width={16}
                  height={16}
                  style={{ verticalAlign: 'middle' }}
                >
                  <use xlinkHref={`${RoleManageIcon}#icon`} />
                </svg> */}
                 <RoleManageIcon style={{
                       verticalAlign: 'middle',
                       width: 16,
                       height: 16,
                       fill: 'currentColor',
                       display: 'inline-block',
                       marginRight:8,
                       marginBottom:6
                     }} />
                {/* </span> */}
                {this.props.data.name}
              </span>
            </label>
            <div className='pull-right'>
              {checkPrivileges('roleDelete') &&
                this.props.data.id !== 2 &&
                this.props.data.id !== 3 &&
                this.props.data.id !== 4 &&
                this.props.data.id !== 5 &&
                this.props.data.id !== 6 &&
                this.props.data.id !== 7 && (
                  <span
                    className='action-item hovered'
                    title='Delete'
                    onClick={() => this.props.removedItem(this.props.data)}
                  >
                    <MaterialIcon
                      size='16'
                      icon='delete'
                      color='currentColor'
                    />
                  </span>
                )}
              {checkPrivileges('roleUpdate') && (
                <span
                  className='action-item hovered'
                  title='Edit'
                  onClick={() => this.props.editItem(this.props.data)}
                >
                  <MaterialIcon size='16' icon='edit' color='currentColor' />{' '}
                </span>
              )}
            </div>
          </Link>
        </li>
      )
    // } 
    // else if (this.props.logInUser.userType === 3 || this.props.logInUser.userType === 4 || this.props.logInUser.userType === 6) {
    //   return (
    //     <li>
    //       <Link
    //         className={
    //           'clearfix list-row ' +
    //           (parseInt(this.props.match.params.id) === this.props.data.id
    //             ? 'active'
    //             : '')
    //         }
    //         to={`/${this.props.pathName}/` + this.props.data.id}
    //       >
    //         <label
    //           className='checkbox'
    //           onClick={() => this.props.selecteItem(this.props.data)}
    //         >
    //           <span
    //             className='unit-name'
    //             style={{
    //               minWidth: 220,
    //               maxWidth: 220,
    //               marginLeft: 0,
    //               lineHeight: '24px'
    //             }}
    //           >
    //             <span
    //               style={{
    //                 marginRight: '10px',
    //                 display: 'inline-block',
    //                 verticalAlign: 'middle'
    //               }}
    //             >
    //               <RoleManageIcon style={{
    //                   verticalAlign: 'middle',
    //                   width: 16,
    //                   height: 16,
    //                   fill: 'currentColor',
    //                   display: 'inline-block',
    //                   marginRight:8,
    //                   marginBottom:6
    //                  }} />
    //             </span>{' '}
    //             {this.props.data.name}
    //           </span>
    //         </label>
    //         <div className='pull-right'>
    //           {checkPrivileges('roleDelete') &&
    //              this.props.data.id !== 2 &&
    //              this.props.data.id !== 3 &&
    //              this.props.data.id !== 4 &&
    //              this.props.data.id !== 5 &&
    //              this.props.data.id !== 6 &&
    //              this.props.data.id !== 7 && (
    //               <span
    //                 className='action-item hovered'
    //                 title='Delete'
    //                 onClick={() => this.props.removedItem(this.props.data)}
    //               >
    //                 <MaterialIcon
    //                   size='16'
    //                   icon='delete'
    //                   color='currentColor'
    //                 />
    //               </span>
    //             )}
    //           {checkPrivileges('roleUpdate') &&
    //             this.props.data.id !== 2 &&
    //             this.props.data.id !== 3 &&
    //             this.props.data.id !== 4 && (
    //               <span
    //                 className='action-item hovered'
    //                 title='Edit'
    //                 onClick={() => this.props.editItem(this.props.data)}
    //               >
    //                 <MaterialIcon size='16' icon='edit' color='currentColor' />{' '}
    //               </span>
    //             )}
    //         </div>
    //       </Link>
    //     </li>
    //   )
    // }
    //  else {
    //   return null
    // }
  }
}

const mapState = state => {
  if (state.roles) {
    var role = state.roles?.filter(
      item => item?.name?.toLowerCase().indexOf(state.searchRole?.toLowerCase()) !== -1
    );
  }
  return {
    logInUser: state.logInUsers,
    roles: role
  }
}

const mapStateToProps = connect(mapState)
export const RoleShortList = mapStateToProps(Role)
